import { useEffect, useState } from "react";

const LoginPage = ({ setAuth, user, setJWT }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      username: username,
      password: password,
    });

    fetch("https://backend.l3xington.dev/api/v2/auth/admin", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          console.log("Response JSON success is true");
          setAuth(true);
          setJWT(data.token);
          localStorage.setItem("jwt", data.token);
        } else {
          console.log("Response JSON success is false or not present");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="mt-25 mr-20">
        <div className="flex flex-col bg-inputs text-white border order border-stroke p-3 rounded-[7px] space-x-3">
          <div className="flex">
            <input
              className="bg-inputs text-white border order border-stroke p-3 rounded-[7px]"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
            <input
              className="bg-inputs text-white border order border-stroke p-3 rounded-[7px]"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Password"
            />
          </div>

          <button
            className="bg-button text-white border order border-stroke mt-10 rounded-[7px] h-10"
            onClick={() => {
              login();
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
