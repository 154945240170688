export const options = [
    { value: "eventime", label: "eventime" },
    { value: "ticketone", label: "ticketone" },
    { value: "ticketmaster-fr", label: "ticketmaster-fr" },
    { value: "ticketmaster-de", label: "ticketmaster-de" },
    { value: "ticketmaster-sg", label: "ticketmaster-sg" },
    { value: "ticketmaster-se", label: "ticketmaster-se" },
    { value: "ticketmaster-at", label: "ticketmaster-at" },
    { value: "ticketmaster-it", label: "ticketmaster-it" },
    { value: "ticketmaster-es", label: "ticketmaster-es" },
    { value: "ticketmaster-nl", label: "ticketmaster-nl" },
    { value: "ticketmaster-cz", label: "ticketmaster-cz" },
{ value: "ticketmaster-pl", label: "ticketmaster-pl" },
{ value: "ticketmaster-pl-resale", label: "ticketmaster-pl-resale" },
{ value: "cricket-t20", label: "cricket-t20" },

    { value: "ticketmaster-cz-resale", label: "ticketmaster-cz-resale" },
    { value: "ticketmaster-at-resale", label: "ticketmaster-at-resale" },
    { value: "ticketmaster-de-resale", label: "ticketmaster-de-resale" },
    { value: "ticketmaster-se-resale", label: "ticketmaster-se-resale" },
    { value: "ticketmaster-es-resale", label: "ticketmaster-es-resale" },
    { value: "ticketmaster-nl-resale", label: "ticketmaster-nl-resale" },
    { value: "psg-restock", label: "psg-restock" },
    { value: "psg-openning", label: "psg-openning" },
    { value: "twickets", label: "twickets" },
    { value: "fansale", label: "fansale" },
    { value: "roland-garros", label: "roland-garros" },
    { value: "accor-arena", label: "accor-arena" },
    { value: "oeticket", label: "oeticket" },
    { value: "ticketcorner", label: "ticketcorner" },
    { value: "lippu.fi", label: "lippu.fi" },
    { value: "snipes", label: "snipes" },
    { value: "solebox", label: "solebox" },
];
