import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";

const NavigationBar = ({}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(
    window.location.pathname.replace("/", "")
  ); 


  return (
    <>
      <nav className="bg-dark-grey fixed w-full z-20 top-0 left-0 border-b border-stroke">
        <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
          <a className="flex items-center">
            <img
              src="https://www.l3xington.dev/static/atom3.png"
              className="h-10 mr-[-20px]"
            />
            <span className="self-center text-xl mx-10 font-semibold whitespace-nowrap text-white">
              Admin Dashboard
            </span>
          </a>

          <div className="inline-block w-[1px] h-7 bg-[#1F2022] mx-2"></div>

          <Link
              className={`m-2 text-[14px] ${
                activeLink === "dashboard" || activeLink === ""
                  ? "bg-[#282828] text-sm rounded-md text-white px-[9px] py-[6px] mx-2"
                  : "text-[#6D6D6E] mx-4"
              }`}
              to="/"
              onClick={() => setActiveLink("dashboard")}
            >
              Dashboard
            </Link>

            <Link
              className={`m-2 text-[14px] ${
                activeLink === "monitors"
                  ? "bg-[#282828] text-sm rounded-md text-white px-[9px] py-[6px] mx-0"
                  : "text-[#6D6D6E] mx-2"
              }`}
              to="/monitors"
              onClick={() => setActiveLink("monitors")}
            >
              Settings
            </Link>

          {/* <div className="flex space-x-2 ">
            <div className="flex justify-center">
              <div className="relative">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="relative z-10 block rounded-md bg-inputs p-2 focus:outline-none border order border-stroke"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z"
                      fill="white"
                    />
                    <path
                      opacity="0.4"
                      d="M14.0087 19.2284C13.5088 19.1216 10.4626 19.1216 9.96269 19.2284C9.53533 19.3271 9.07318 19.5567 9.07318 20.0602C9.09803 20.5406 9.37929 20.9647 9.76888 21.2336L9.76789 21.2346C10.2718 21.6273 10.8631 21.8771 11.4823 21.9668C11.8123 22.0121 12.1482 22.0101 12.4901 21.9668C13.1083 21.8771 13.6996 21.6273 14.2035 21.2346L14.2025 21.2336C14.5921 20.9647 14.8734 20.5406 14.8982 20.0602C14.8982 19.5567 14.4361 19.3271 14.0087 19.2284Z"
                      fill="white"
                    />
                  </svg>
                </button>

                {dropdownOpen && (
                  <div
                    onClick={() => setDropdownOpen(false)}
                    className="fixed inset-0 h-full w-full z-10"
                  ></div>
                )}

                {dropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 bg-dark-grey border-stroke border order rounded-md shadow-lg overflow-hidden z-20"
                    style={{ width: "20rem" }}
                  >
                    <div className="px-5 py-4">
                      <div className="flex space-x-3">
                        <h1 className="text-white">Notifications</h1>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 mt-1"
                        >
                          <path
                            d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z"
                            fill="white"
                          />
                          <path
                            opacity="0.4"
                            d="M14.0087 19.2284C13.5088 19.1216 10.4626 19.1216 9.96269 19.2284C9.53533 19.3271 9.07318 19.5567 9.07318 20.0602C9.09803 20.5406 9.37929 20.9647 9.76888 21.2336L9.76789 21.2346C10.2718 21.6273 10.8631 21.8771 11.4823 21.9668C11.8123 22.0121 12.1482 22.0101 12.4901 21.9668C13.1083 21.8771 13.6996 21.6273 14.2035 21.2346L14.2025 21.2336C14.5921 20.9647 14.8734 20.5406 14.8982 20.0602C14.8982 19.5567 14.4361 19.3271 14.0087 19.2284Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <p className="text-gray-500 text-sm">
                        3 New Notifications
                      </p>
                    </div>
                    <hr className=" border-t-1 border-stroke" />
                    <div
                      className="mt-3 mb-5"
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                      <div className="px-4 py-2">
                        <Notification />
                      </div>
                      <div className="px-4 py-2">
                        <Notification />
                      </div>
                      <div className="px-4 py-2">
                        <Notification />
                      </div>
                      <div className="px-4 py-2">
                        <Notification />
                      </div>
                      <div className="px-4 py-2">
                        <Notification />
                      </div>
                      <div className="px-4 py-2">
                        <Notification />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </>
  );
};

const Notification = () => {
  return (
    <div className="py-2 bg-inputs border order border-stroke rounded-md">
      <a href="#" className="flex items-center px-4 py-3 ">
        <p className="text-gray-300 text-sm mx-2">
          <h1 className="text-white text-base">New Client Added!</h1>
          <p className="text-gray-500">tom#123</p>

          <div className="flex">
            <p className="text-gray-500 mr-1">By User:</p>
            <p>Jonah</p>

            <p className="text-blue-300 ml-10">Mark As Read</p>
          </div>
        </p>
      </a>
    </div>
  );
};

export default NavigationBar;
