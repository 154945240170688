import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import DocumentIcon from "../assets/document.svg";
import CancelIcon from "../assets/cancel-x.svg";
import { options } from "./options.js";
import Select from "react-select";

const AddPidElem = (jwt) => {
  const [userIdentifier, setUserIdentifier] = useState("");
  const [pid, setPid] = useState("");
  const [pidList , setPidList] = useState([])
  const [selectedValues, setSelectedValues] = useState();

  let headersList = {
    Accept: "*/*",
    authorization: jwt.jwt,
    "Content-Type": "application/json",
  };

  const removePid = (pidToRemove) => {
    const updatedPidList = pidList.filter(item => item.pid !== pidToRemove);
    setPidList(updatedPidList);
  };

  const appendPid = (newPid) => {
    const updatedPidList = [{"pid":newPid}, ...pidList];
    setPidList(updatedPidList);
  };

  function AddPid(website, pid) {
    let bodyContent = JSON.stringify({
      pid: pid,
      website: website,
      extra: {},
    });

    fetch(
      "https://backend.l3xington.dev/api/v2/admin/monitors/pids_regions/add",
      {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      }
    );

    appendPid(pid)
  }

  const handleSetSelectedValues = (values) => {
    setSelectedValues(values);
    let price = 0;
    values.forEach((value) => {
      setPidList([])
      fetch(`https://backend.l3xington.dev/api/v2/admin/monitors/pids_regions/get/${value}`, {
        headers: {
          authorization: jwt.jwt,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          let pids = data[0].pids
          setPidList(pids)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });
  };

 

  return (
    
    <div className="flex flex-col mt-28 mx-16 border order border-stroke min-w-400 w-3/4 px-4 py-6 rounded-md bg-dark-grey">
      <div className="flex items-center w-full py-4 mt-[-12px]">
        <div className="bg-inputs p-4 border border-stroke rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#8A8A8A"
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
            />
          </svg>
        </div>
        <div className="mx-4">
          <h3 className="text-lg text-white font-bold">Custom Pids</h3>
          <div className="flex">
            <div className="flex-grow">
              <p className="text-gray-500">
                Enter a PID for a specific website below
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="border-t border-stroke" />

      <div className="flex justify-between">
        <div>
          <h3 className="text-lg mb-3 font-semibold text-white my-1 mt-2">
            Add Pids
          </h3>
        </div>
      </div>

      <div className="flex flex-col">
      

        <Select
                  className=" text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm outline-none"
                  options={options}
                  // isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#252631",
                      backgroundColor: "#191B27",
                      outline: "none",
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      height: 38,
                      overflow: "auto",
                    }),
                    singleValue:(provided,state) => ({
                      ...provided,
                      color:'#BDBDBD',
                    }),
                  }}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption ? selectedOption.value : null;
                    handleSetSelectedValues([selectedValue]);
                  }}
                />
        <div className="flex flex-row space-x-3">
          <input
            type="text"
            placeholder="Enter Pid"
            onChange={(e) => {
              setPid(e.target.value);
            }}
            value={pid}
            className="outline-none text-gray-400 text-sm mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full"
          />

          <button
            style={{
              background:
                "linear-gradient(90deg, #6166DC 0%, #9397E8 100%), #FFFFFF",
            }}
            onClick={() => {
              AddPid(selectedValues[0], pid);
            }}
            className="px-5 h-8 text-white text-sm rounded-[8px] hover:bg-gray-300 mt-1"
          >
            Add
          </button>
        </div>

        <hr className="border-t border-stroke mt-1 mb-1" />

        <div className="flex justify-between">
          <div className="w-full">
            <h3 className="text-lg mb-3 font-semibold text-white my-1 mt-2">
              Added Pids
            </h3>
            <div id="members" className="max-h-56 overflow-y-auto">
              {pidList.map((item, index) => {
              return (
                <AddedPidsElement
                  pid={item.pid}
                  website={selectedValues[0]}
                  headersList={headersList}
                  removePid={removePid}
                />
              );
            })}
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddedPidsElement = ({pid,website,headersList,removePid}) => {

  function RemovePid(website,pid){
    const json_data = {
      pid: pid,
      website: website,
      extra: {},
    };
    
    const url = 'https://backend.l3xington.dev/api/v2/admin/monitors/pids_regions/remove';
    
    fetch(url, {
      method: 'POST',
      headers:headersList,
      body: JSON.stringify(json_data),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        removePid(pid)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  return (
    <div className="flex w-full justify-between mt-2 border order p-2 border-stroke rounded-md">
      <div
        style={{
          background: "rgba(118, 122, 225, 0.19)",
        }}
        className="rounded-md p-1"
      >
        <img src={DocumentIcon} alt="SVG" className="h-8" />
      </div>
      <div className=" mx-4">
        <p className="text-white text-[13px]">{pid}</p>
        <p className="text-[#7C7C7D] text-[12px]">{website}</p>
      </div>
      <div className="flex-grow" />{" "}
      <button 
      onClick={() => RemovePid(website, pid)}>
        <img src={CancelIcon} alt="SVG" className="mt-1" />
      </button>
    </div>
  );
};

export default AddPidElem;
