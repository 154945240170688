import SingleMember from "./SingleMember";
import { useEffect, useState } from "react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { options } from "./options.js"; // or the correct relative path to the options.js file

const HandleClients = (jwt) => {
  const [members, setMembers] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [priceField, setPriceField] = useState("0");
  console.log("jwt: ", jwt.jwt);

  const handleSetSelectedValues = (values) => {
    setSelectedValues(values);
    let price = 0;
    values.forEach((value) => {
      fetch(`https://backend.l3xington.dev/api/v2/public/pricing/get/${value}`, {
        headers: {
          authorization: jwt.jwt,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const itemPrice = data[0].price;
          price = price + parseFloat(itemPrice);
          setPriceField(price.toString());
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });

    if (values.length === 0) {
      setPriceField("0");
    }
  };

  const LoadUsers = () => {
    fetch("https://backend.l3xington.dev/api/v2/admin/users/get", {
      method: "GET",
      headers: {
        authorization: jwt.jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMembers(data);
      });
  };

  useEffect(() => {
    LoadUsers();
  }, []);

  const [searchuserIccon, setSearchUserIcon] = useState("");

  const HandleDiscordChange = (e) => {
    fetch(`https://discordlookup.l3xington.dev/id/${e.target.value}`)
      .then((response) => response.json())
      .then((data) => {
        const tag = data.username;
        const avatarLink = `https://cdn.discordapp.com/avatars/${e.target.value}/${data.avatar}`;
        setSearchUserIcon(avatarLink);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setDiscordIdField(e.target.value);
  };

  const [discordIdField, setDiscordIdField] = useState("");

  const AddClient = async () => {
    let headersList = {
      Accept: "*/*",
      authorization: jwt.jwt,
      "Content-Type": "application/json",
    };

    let uderid = uuidv4();

    let bodyContent = JSON.stringify({
      user: {
        discordId: [discordIdField],
        userType: "client",
        userIdentifier: uderid,
        monitorIds: [],
      },
    });

    let response = await fetch(
      "https://backend.l3xington.dev/api/v2/admin/users/add",
      {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      }
    );

    let data = response.text;

    setTimeout(async function () {
      for (let value of selectedValues) {
        let monitorid = uuidv4();

        let AddbodyContent = JSON.stringify({
          monitor: {
            monitorid: monitorid,
            userIdentifier: uderid,
            website: value,
            stripeEntry: {},
            Active: false,
            webhook: {
              footericon: "https://media.discordapp.net/",
              footertext: "Lexington Monitor",
              webhookcolor: 0,
              webhookurl: "https://discord.com",
            },
          },
        });

        await fetch("https://backend.l3xington.dev/api/v2/admin/monitors/add", {
          method: "POST",
          body: AddbodyContent,
          headers: headersList,
        });

        let bodyContent = JSON.stringify({
          user: {
            discordId: [discordIdField],
            userIdentifier: uderid,
            monitorIds: [monitorid],
          },
        });
      }
    }, 2000);

    setTimeout(function () {
      LoadUsers();
    }, 1000);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const DeleteTeamMember = (key, member) => {
    let headersList = {
      Accept: "*/*",
      authorization: jwt.jwt,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      userIdentifier: member,
    });

    let response = fetch("https://backend.l3xington.dev/api/v2/admin/users/remove", {
      method: "DELETE",
      body: bodyContent,
      headers: headersList,
    });

    let data = response.text;
    console.log(data);

    console.log("key: ", key, member);
    const newTeam = members.filter((_, index) => index !== key);
    console.log("newTeam: ", newTeam);
    setMembers(newTeam);
  };

  return (
    <div className="flex flex-col m-20 border order-1 border-stroke px-4 py-6 rounded-md mt-[-50px] bg-dark-grey">
      <div className="flex items-center py-4 mt-[-12px]">
        <div className="bg-inputs p-3 border border-stroke rounded-md">
          <svg
            width="30"
            height="32"
            viewBox="0 0 30 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9363 19.4094C10.6238 19.4094 6.98511 20.1337 6.98511 22.9294C6.98511 25.7262 10.6473 26.4252 14.9363 26.4252C19.2488 26.4252 22.8875 25.7008 22.8875 22.9051C22.8875 20.1083 19.2253 19.4094 14.9363 19.4094"
              fill="white"
            />
            <path
              opacity="0.4"
              d="M14.9363 16.7458C17.8564 16.7458 20.1979 14.3251 20.1979 11.306C20.1979 8.28588 17.8564 5.86628 14.9363 5.86628C12.0162 5.86628 9.67468 8.28588 9.67468 11.306C9.67468 14.3251 12.0162 16.7458 14.9363 16.7458"
              fill="white"
            />
            <path
              opacity="0.4"
              d="M26.36 12.5727C27.1155 9.51778 24.9005 6.77418 22.0799 6.77418C21.7733 6.77418 21.48 6.80889 21.1935 6.86791C21.1554 6.87716 21.1129 6.89684 21.0905 6.93155C21.0648 6.97552 21.0838 7.03454 21.1118 7.07272C21.9591 8.30162 22.4459 9.79781 22.4459 11.4039C22.4459 12.943 21.9993 14.3778 21.2159 15.5685C21.1353 15.6912 21.2069 15.8567 21.3491 15.8821C21.5461 15.918 21.7475 15.9365 21.9535 15.9423C24.0073 15.9978 25.8507 14.6312 26.36 12.5727"
              fill="white"
            />
            <path
              d="M28.5116 19.7653C28.1355 18.9368 27.2278 18.3686 25.8478 18.0897C25.1963 17.9254 23.4335 17.694 21.7938 17.7252C21.7692 17.7287 21.7558 17.7461 21.7535 17.7576C21.7502 17.7738 21.7569 17.8016 21.7893 17.819C22.5471 18.2066 25.4762 19.8926 25.1079 23.4485C25.0923 23.6024 25.212 23.7355 25.3609 23.7123C26.0817 23.6059 27.9363 23.1939 28.5116 21.9107C28.8294 21.2326 28.8294 20.4445 28.5116 19.7653"
              fill="white"
            />
            <path
              opacity="0.4"
              d="M8.80604 6.86826C8.52063 6.80809 8.22627 6.77453 7.91959 6.77453C5.09907 6.77453 2.88407 9.51814 3.64069 12.573C4.14883 14.6316 5.99224 15.9982 8.04607 15.9426C8.25201 15.9369 8.45459 15.9172 8.65046 15.8825C8.79261 15.857 8.86424 15.6915 8.78366 15.5689C8.00018 14.377 7.5536 12.9433 7.5536 11.4043C7.5536 9.79701 8.04159 8.30081 8.88887 7.07307C8.91573 7.03489 8.93587 6.97587 8.90901 6.9319C8.88663 6.89603 8.84521 6.87752 8.80604 6.86826"
              fill="white"
            />
            <path
              d="M4.15194 18.0894C2.7719 18.3683 1.86531 18.9364 1.48924 19.7649C1.17025 20.4442 1.17025 21.2322 1.48924 21.9115C2.06454 23.1936 3.91914 23.6067 4.63994 23.712C4.7888 23.7351 4.90744 23.6032 4.89177 23.4482C4.52354 19.8934 7.45262 18.2074 8.21148 17.8198C8.24281 17.8013 8.24953 17.7746 8.24617 17.7573C8.24393 17.7457 8.23162 17.7284 8.207 17.726C6.56617 17.6936 4.80447 17.9251 4.15194 18.0894"
              fill="white"
            />
          </svg>
        </div>

        <div className="flex-grow-1 mx-3">
          <h3 className="text-lg text-white font-bold ">Manage Clients</h3>
          <div className="flex">
            <p className="text-gray-500">{members.length} Client's</p>
          </div>
        </div>

        <button
          className="mt-[-20px] ml-auto px-6 h-8 w-auto text-sm rounded-[8px] whitespace-nowrap text-white"
          style={{
            background:
              "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
          }}
          onClick={toggleModal}
        >
          Add Client
        </button>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 ">
            <div className="bg-gray-900  inset-0"></div>
            <div className="flex-col m-20 border order-1 border-stroke w-1/3 px-4 py-6 rounded-md mt-[-50px] bg-dark-grey">
              <div className="flex">
                <div className="bg-inputs p-3 border border-stroke rounded-md">
                  <svg
                    width="30"
                    height="32"
                    viewBox="0 0 30 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9363 19.4094C10.6238 19.4094 6.98511 20.1337 6.98511 22.9294C6.98511 25.7262 10.6473 26.4252 14.9363 26.4252C19.2488 26.4252 22.8875 25.7008 22.8875 22.9051C22.8875 20.1083 19.2253 19.4094 14.9363 19.4094"
                      fill="white"
                    />
                    <path
                      opacity="0.4"
                      d="M14.9363 16.7458C17.8564 16.7458 20.1979 14.3251 20.1979 11.306C20.1979 8.28588 17.8564 5.86628 14.9363 5.86628C12.0162 5.86628 9.67468 8.28588 9.67468 11.306C9.67468 14.3251 12.0162 16.7458 14.9363 16.7458"
                      fill="white"
                    />
                    <path
                      opacity="0.4"
                      d="M26.36 12.5727C27.1155 9.51778 24.9005 6.77418 22.0799 6.77418C21.7733 6.77418 21.48 6.80889 21.1935 6.86791C21.1554 6.87716 21.1129 6.89684 21.0905 6.93155C21.0648 6.97552 21.0838 7.03454 21.1118 7.07272C21.9591 8.30162 22.4459 9.79781 22.4459 11.4039C22.4459 12.943 21.9993 14.3778 21.2159 15.5685C21.1353 15.6912 21.2069 15.8567 21.3491 15.8821C21.5461 15.918 21.7475 15.9365 21.9535 15.9423C24.0073 15.9978 25.8507 14.6312 26.36 12.5727"
                      fill="white"
                    />
                    <path
                      d="M28.5116 19.7653C28.1355 18.9368 27.2278 18.3686 25.8478 18.0897C25.1963 17.9254 23.4335 17.694 21.7938 17.7252C21.7692 17.7287 21.7558 17.7461 21.7535 17.7576C21.7502 17.7738 21.7569 17.8016 21.7893 17.819C22.5471 18.2066 25.4762 19.8926 25.1079 23.4485C25.0923 23.6024 25.212 23.7355 25.3609 23.7123C26.0817 23.6059 27.9363 23.1939 28.5116 21.9107C28.8294 21.2326 28.8294 20.4445 28.5116 19.7653"
                      fill="white"
                    />
                    <path
                      opacity="0.4"
                      d="M8.80604 6.86826C8.52063 6.80809 8.22627 6.77453 7.91959 6.77453C5.09907 6.77453 2.88407 9.51814 3.64069 12.573C4.14883 14.6316 5.99224 15.9982 8.04607 15.9426C8.25201 15.9369 8.45459 15.9172 8.65046 15.8825C8.79261 15.857 8.86424 15.6915 8.78366 15.5689C8.00018 14.377 7.5536 12.9433 7.5536 11.4043C7.5536 9.79701 8.04159 8.30081 8.88887 7.07307C8.91573 7.03489 8.93587 6.97587 8.90901 6.9319C8.88663 6.89603 8.84521 6.87752 8.80604 6.86826"
                      fill="white"
                    />
                    <path
                      d="M4.15194 18.0894C2.7719 18.3683 1.86531 18.9364 1.48924 19.7649C1.17025 20.4442 1.17025 21.2322 1.48924 21.9115C2.06454 23.1936 3.91914 23.6067 4.63994 23.712C4.7888 23.7351 4.90744 23.6032 4.89177 23.4482C4.52354 19.8934 7.45262 18.2074 8.21148 17.8198C8.24281 17.8013 8.24953 17.7746 8.24617 17.7573C8.24393 17.7457 8.23162 17.7284 8.207 17.726C6.56617 17.6936 4.80447 17.9251 4.15194 18.0894"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="flex-grow-1 mx-3 w-full">
                  <div className="flex">
                    <h3 className="text-lg text-white font-bold ">
                      Add Client
                    </h3>
                    <button onClick={toggleModal} className="ml-auto">
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="3.95984"
                            y="5.9397"
                            width="2.8"
                            height="14"
                            rx="1"
                            transform="rotate(-45 3.95984 5.9397)"
                            fill="#8A8A8A"
                          />
                          <rect
                            x="5.93964"
                            y="15.8391"
                            width="2.8"
                            height="14"
                            rx="1"
                            transform="rotate(-135 5.93964 15.8391)"
                            fill="#8A8A8A"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                  <div className="flex">
                    <p className="text-gray-500">Enter Details to continue</p>
                  </div>
                </div>
              </div>

              <hr className="my-4 border-t-1 border-stroke" />
              <div className="flex space-x-4 w-full">
                <div className="relative w-1/2">
                  <input
                    type="text"
                    placeholder="Enter Discord ID"
                    value={
                      discordIdField.length > 12
                        ? discordIdField.slice(0, 12) + "..."
                        : discordIdField
                    }
                    onChange={HandleDiscordChange}
                    className="outline-none text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm pr-8" // added pr-8 for padding
                  ></input>
                  {searchuserIccon && (
                    <img
                      src={searchuserIccon}
                      className="absolute right-2 rounded-sm top-[21px] transform -translate-y-1/2 h-6 w-6" // adjusted size and position
                    />
                  )}
                </div>

                <Select
                  className=" text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-1/2 text-sm outline-none"
                  options={options}
                  isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#252631",
                      backgroundColor: "#191B27",
                      outline: "none",
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      height: 38,
                      overflow: "auto",
                    }),
                  }}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    handleSetSelectedValues(values);
                  }}
                />
              </div>
              <div className="flex space-x-4">
                <input
                  type="text"
                  placeholder="Enter Custom Price"
                  value={priceField}
                  onChange={(e) => setPriceField(e.target.value)}
                  className="outline-none text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm"
                ></input>
                <select className="outline-none text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm">
                  <option value="" disabled selected>
                    Select Payment
                  </option>
                  <option value="creditCard">Credit Card</option>
                  <option value="paypal">PayPal</option>
                </select>
              </div>
              <button
                className="mt-[-20px] ml-auto px-6 h-8 w-auto text-sm rounded-[8px] whitespace-nowrap text-white"
                style={{
                  background:
                    "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
                }}
                onClick={() => {
                  toggleModal();
                  AddClient();
                }}
              >
                Add Client
              </button>
            </div>
          </div>
        )}
      </div>

      <hr className="my-4 border-t-1 border-stroke" />
      <div>
        <h1 className="text-1xl font-semibold text-white">All Clients</h1>
        <div id="members" className="h-[300px] overflow-y-auto">
          {members.map((member, index) => {
            return (
              <SingleMember
                member={member}
                index={index}
                key={index}
                jwt={jwt}
                DeleteTeamMember={DeleteTeamMember}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HandleClients;
