import { Tooltip, Button } from "@material-tailwind/react";
import { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import { options } from "./options.js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SingleMember = ({ member, DeleteTeamMember, jwt, index }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isLookup, setLookup] = useState(false);
  const [editStripe, setEditStripe] = useState(false);
  const [DeleteMenuClicked, setDeleteIsClicked] = useState(false);
  const [EditMember, setEditMember] = useState(false);
  const [username, setUserName] = useState("Unknown");
  const [userImage, setUserImage] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const [isUserActive, setIsUserActive] = useState(false);
  const [totalprice, setTotalPrice] = useState(0);
  const [NewPrice, setNewPrice] = useState(0);

  let headersList = {
    Accept: "*/*",
    authorization: jwt.jwt,
    "Content-Type": "application/json",
  };

  console.log(jwt.jwt)

  const CheckforActive = async () => {
    const apiUrl = member.user.stripeentry.subscriptionId
      ? "https://backend.l3xington.dev/api/v2/stripe/check/" +
        member.user.stripeentry.subscriptionId
      : "https://backend.l3xington.dev/api/v2/stripe/check/na";

    fetch(apiUrl,{
      headers: headersList,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.active);
        setIsUserActive(data.active);
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
    try {
      const response = await fetch(
        `https://backend.l3xington.dev/api/v2/admin/users/price/${member.user.discordid[0]}`,
        {
          method: "GET",
          headers: headersList,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      let total = data.total;
      setTotalPrice(total);
    } catch (error) {}
  };

  const GetUserData = () => {
    fetch(
      `https://discordlookup.l3xington.dev/id/${member.user.discordid[0]}`
    )
      .then((response) => response.json())
      .then((data) => {
        const username = data.username;
        const avatarLink = `https://cdn.discordapp.com/avatars/${member.user.discordid[0]}/${data.avatar}`;
        setUserName(username);
        setUserImage(avatarLink);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const PreviewUser = () => {
    fetch(
      `https://backend.l3xington.dev/api/v2/admin/login_preview/${member.user.discordid[0]}`,
      {
        method:"POST",
        headers: {
          authorization: jwt.jwt,
        },
      }
    ) .then((response) => response.json())
    .then((data) => {
      window.open("https://dash.l3xington.dev/?user="+data.token)
    })
    .catch((error) => {
      console.error("Error: ", error);
    });
    
  }

  useEffect(() => {
    axios
    .get(
      "https://backend.l3xington.dev/api/v2/public/monitors/get/" +
        member.user.discordid[0],
      {
        headers: {
          authorization: jwt.jwt,
        },
      }
    )

    .then((response) => {
      const data = response.data;
      if (data && data.length > 0) {
        const websites = data.map((item) => item.website);
        const preselectedOptions = options.filter((option) =>
          websites.includes(option.value)
        );
        setSelectedValues(preselectedOptions);
        setInitialValues(preselectedOptions);
      }
    })
    .catch((err) => {
      console.error(err);
    });
    CheckforActive();
    GetUserData();
  }, []);


  console.log(1,selectedValues)

  const GetStripeCheckout = async () => {
    // const id = toast.loading("Please Wait...");

    try {
      console.log(2,selectedValues)
      
      if (selectedValues.length > 0) {
        const valuesArray = selectedValues.map(item => item.value);

        console.log("useriden",member.user)
        let bodyContent = JSON.stringify({
          userIdentifier: member.user.useridentifier,
          websites: valuesArray
        });

        let responseStripe = await fetch(
          "https://backend.l3xington.dev/api/v2/stripe/checkout",
          {
            method: "POST",
            body: bodyContent,
            headers: headersList,
          }
        );

        if (!responseStripe.ok) {
          throw new Error(`HTTP error! status: ${responseStripe.status}`);
        }

        let dataStripe = await responseStripe.json();

        console.log(dataStripe);
        prompt("Copy to clipboard: Ctrl+C, Enter", dataStripe.url);

        if (document.hasFocus()) {
          await navigator.clipboard.writeText(dataStripe.url);
        } else {
          alert('Please click "OK" to copy the URL');
          document.querySelector("#copy-button").onclick = async () => {
            await navigator.clipboard.writeText(dataStripe.url);
          };
        }

      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetNewStripeCheckout = async () => {
    // const id = toast.loading("Please Wait...");
    // to create a stripe checkout only for the new sub price
    try {
      const valuesArray = selectedValues.map(item => item.value);
      if (NewPrice > 0) {
        console.log("useriden",member.user)
        let bodyContent = JSON.stringify({
          userIdentifier: member.user.useridentifier,
          websites:valuesArray,
        });

        let responseStripe = await fetch(
          "https://backend.l3xington.dev/api/v2/stripe/checkout",
          {
            method: "POST",
            body: bodyContent,
            headers: headersList,
          }
        );

        if (!responseStripe.ok) {
          throw new Error(`HTTP error! status: ${responseStripe.status}`);
          console.log(responseStripe);
        }

        let dataStripe = await responseStripe.json();

        console.log(dataStripe);
        prompt("Copy to clipboard: Ctrl+C, Enter", dataStripe.url);

        if (document.hasFocus()) {
          await navigator.clipboard.writeText(dataStripe.url);
        } else {
          alert('Please click "OK" to copy the URL');
          document.querySelector("#copy-button").onclick = async () => {
            await navigator.clipboard.writeText(dataStripe.url);
          };
        }

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    let headersList = {
      Accept: "*/*",
      authorization: jwt.jwt,
      "Content-Type": "application/json",
    };

    const addedWebsites = selectedValues.filter(
      (option) => !initialValues.includes(option)
    );

    const removedWebsites = initialValues.filter(
      (option) => !selectedValues.includes(option)
    );

    setInitialValues((prevValues) => [
      ...prevValues,
      ...addedWebsites
    ]);

    setInitialValues((prevValues) => prevValues.filter((option) => !removedWebsites.includes(option)));

    console.log("CLICKED ",addedWebsites,removedWebsites)

    addedWebsites.forEach((website) => {
      const monitorid = uuidv4();
      let bodyContent = JSON.stringify({
        monitor: {
          monitorid: monitorid,
          useridentifier: member.user.useridentifier,
          website: website.value,
          stripeEntry: {},
          Active: false,
          webhook: {
            footericon: "https://media.discordapp.net/",
            footertext: "Lexington Monitor",
            webhookcolor: 0,
            webhookurl: "https://discord.com",
          },
        },
      });

      fetch("https://backend.l3xington.dev/api/v2/admin/monitors/add", {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
    });

    removedWebsites.forEach((website) => {
      fetch("https://backend.l3xington.dev/api/v2/admin/monitors/remove", {
        method: "DELETE",
        body: JSON.stringify({
          useridentifier: member.user.useridentifier,
          website: website.value,
        }),
        headers: headersList,
      });
    });
  };


  return (
    <>
      <ToastContainer autoClose={2000} />
      {EditMember && (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
          <div className="bg-gray-900  inset-0"></div>
          <div className="flex-col m-20 border order-1 border-stroke w-1/3 px-4 py-6 rounded-md mt-[-50px] bg-dark-grey">
            <div className="flex">
              <div className="bg-inputs p-3 border border-stroke rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 -2 15 22"
                  fill="#6A6E81"
                >
                  <path
                    opacity="0.4"
                    d="M12.6079 11.9524H9.01694C8.66658 11.9524 8.38162 12.2419 8.38162 12.5978C8.38162 12.9543 8.66658 13.2431 9.01694 13.2431H12.6079C12.9583 13.2431 13.2432 12.9543 13.2432 12.5978C13.2432 12.2419 12.9583 11.9524 12.6079 11.9524"
                    fill="#7C829F"
                  />
                  <path
                    d="M6.50119 4.35383L9.90398 7.10343C9.98606 7.16919 10.0001 7.28986 9.93601 7.37392L5.90191 12.6304C5.64832 12.9552 5.2746 13.1389 4.8742 13.1457L2.67196 13.1728C2.5545 13.1741 2.45173 13.0928 2.42504 12.9762L1.92453 10.8001C1.83777 10.4001 1.92453 9.98659 2.17812 9.66797L6.23225 4.38637C6.29764 4.30163 6.41843 4.28671 6.50119 4.35383"
                    fill="#7C829F"
                  />
                  <path
                    opacity="0.4"
                    d="M11.4274 5.46465L10.7714 6.28356C10.7053 6.36694 10.5865 6.3805 10.5045 6.31407C9.70698 5.6687 7.6649 4.01256 7.09832 3.55361C7.01557 3.48582 7.00423 3.36516 7.07096 3.28109L7.70361 2.4954C8.27752 1.75647 9.27854 1.68868 10.086 2.3327L11.0136 3.07162C11.394 3.3699 11.6476 3.76309 11.7344 4.17661C11.8345 4.63149 11.7277 5.07824 11.4274 5.46465"
                    fill="#7C829F"
                  />
                </svg>
              </div>
              <div className="flex-grow-1 mx-3 w-full">
                <div className="flex">
                  <h3 className="text-lg text-white font-bold">
                    Edit User Websites.
                  </h3>
                  <button
                    onClick={() => {
                      setEditMember(!EditMember);
                    }}
                    className="ml-auto"
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3.95984"
                          y="5.9397"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-45 3.95984 5.9397)"
                          fill="#8A8A8A"
                        />
                        <rect
                          x="5.93964"
                          y="15.8391"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-135 5.93964 15.8391)"
                          fill="#8A8A8A"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className="flex">
                  <p className="text-gray-500">Select a Option</p>
                </div>
              </div>
            </div>

            <hr className="my-4 border-t-1 border-stroke" />
            <div className="flex space-x-4">
              <Select
                className=" text-gray-400 mb-4  h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm outline-none"
                options={options}
                isMulti
                isClearable={true}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#252631",
                    backgroundColor: "#191B27",
                    outline: "none",
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: 40,
                    overflow: "auto",
                  }),
                }}
                onChange={(selectedOptions) => {
                  setSelectedValues(selectedOptions);
                }}
                value={selectedValues}
              />
            </div>
            <button
              className="mt-[-20px] ml-auto px-6 h-8 w-auto text-sm rounded-[8px] whitespace-nowrap text-white"
              style={{
                background:
                  "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
              }}
              onClick={() => {
                handleSubmit();
                setEditMember(!EditMember);
              }}
            >
              Edit Client
            </button>
          </div>
        </div>
      )}

      {DeleteMenuClicked && (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
          <div className="bg-gray-900  inset-0"></div>
          <div className="flex-col m-20 border order-1 border-stroke w-1/3 px-4 py-6 rounded-md mt-[-50px] bg-dark-grey">
            <div className="flex">
              <div className="bg-inputs p-3 border border-stroke rounded-md">
                <svg
                  className="m-1"
                  width="22"
                  height="22"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.2152 3.93226C15.5069 3.93226 15.7498 4.17451 15.7498 4.48276V4.76776C15.7498 5.0685 15.5069 5.31825 15.2152 5.31825H2.78522C2.49273 5.31825 2.24983 5.0685 2.24983 4.76776V4.48276C2.24983 4.17451 2.49273 3.93226 2.78522 3.93226H4.97201C5.41622 3.93226 5.80281 3.61652 5.90274 3.17102L6.01726 2.65953C6.19523 1.96278 6.78095 1.50004 7.45129 1.50004H10.5484C11.2114 1.50004 11.8037 1.96278 11.9751 2.62278L12.0977 3.17027C12.1969 3.61652 12.5834 3.93226 13.0284 3.93226H15.2152ZM14.1041 14.3506C14.3324 12.2229 14.7321 7.16791 14.7321 7.11691C14.7467 6.96241 14.6964 6.81617 14.5964 6.69842C14.4892 6.58817 14.3535 6.52292 14.204 6.52292H3.8011C3.65084 6.52292 3.50787 6.58817 3.40867 6.69842C3.30801 6.81617 3.25841 6.96241 3.26571 7.11691C3.26705 7.12628 3.28139 7.30435 3.30537 7.60204C3.41189 8.92448 3.70858 12.6077 3.9003 14.3506C4.03597 15.6346 4.87844 16.4416 6.09875 16.4708C7.04043 16.4926 8.01055 16.5001 9.00255 16.5001C9.93693 16.5001 10.8859 16.4926 11.8567 16.4708C13.1194 16.4491 13.9611 15.6563 14.1041 14.3506Z"
                    fill="#6A6E81"
                  />
                </svg>
              </div>
              <div className="flex-grow-1 mx-3 w-full">
                <div className="flex">
                  <h3 className="text-lg text-white font-bold">
                    Confirm user deletion.
                  </h3>
                  <button
                    onClick={() => {
                      setDeleteIsClicked(!DeleteMenuClicked);
                    }}
                    className="ml-auto"
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3.95984"
                          y="5.9397"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-45 3.95984 5.9397)"
                          fill="#8A8A8A"
                        />
                        <rect
                          x="5.93964"
                          y="15.8391"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-135 5.93964 15.8391)"
                          fill="#8A8A8A"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className="flex">
                  <p className="text-gray-500">Select a Option</p>
                </div>
              </div>
            </div>

            <hr className="my-4 border-t-1 border-stroke" />
            <div className="space-x-3 flex">
              <button
                className="px-4 w-full h-8 text-sm rounded-[8px] whitespace-nowrap text-white"
                onClick={() => {
                  DeleteTeamMember(index, member.user.useridentifier);
                  setDeleteIsClicked(!DeleteMenuClicked);
                }}
                style={{
                  background:
                    "linear-gradient(135deg, #FF6F6F 0%, #F61414 110%), linear-gradient(90deg, #DC6161 0%, #E89393 100%), linear-gradient(135deg, #FF6F6F 0%, #F61414 100%)",
                }}
              >
                Remove User
              </button>
            </div>
          </div>
        </div>
      )}

      {isClicked && (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
          <div className="bg-gray-900  inset-0"></div>
          <div className="flex-col m-20 border order-1 border-stroke w-1/3 px-4 py-6 rounded-md mt-[-50px] bg-dark-grey">
            <div className="flex">
              <div className="bg-inputs p-3 border border-stroke rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-gray-500"
                >
                  <path d="M13.479 9.883c-1.626-.604-2.512-1.067-2.512-1.803 0-.622.511-.977 1.423-.977 1.667 0 3.379.642 4.558 1.22l.666-4.111c-.935-.446-2.847-1.177-5.49-1.177-1.87 0-3.425.489-4.536 1.401-1.155.954-1.757 2.334-1.757 4 0 3.023 1.847 4.312 4.847 5.403 1.936.688 2.579 1.178 2.579 1.934 0 .732-.629 1.155-1.762 1.155-1.403 0-3.716-.689-5.231-1.578l-.674 4.157c1.304.732 3.705 1.488 6.197 1.488 1.976 0 3.624-.467 4.735-1.356 1.245-.977 1.89-2.422 1.89-4.289 0-3.091-1.889-4.38-4.935-5.468h.002z"></path>
                </svg>
              </div>
              <div className="flex-grow-1 mx-3 w-full">
                <div className="flex">
                  <h3 className="text-lg text-white font-bold">
                    Manage Stripe Subscription
                  </h3>
                  <button
                    onClick={() => {
                      if (editStripe) {
                        setEditStripe(!editStripe);
                      } else {
                        setIsClicked(!isClicked);
                      }
                    }}
                    className="ml-auto"
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3.95984"
                          y="5.9397"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-45 3.95984 5.9397)"
                          fill="#8A8A8A"
                        />
                        <rect
                          x="5.93964"
                          y="15.8391"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-135 5.93964 15.8391)"
                          fill="#8A8A8A"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className="flex">
                  <p className="text-gray-500">Select a Option</p>
                </div>
              </div>
            </div>

            <hr className="my-4 border-t-1 border-stroke" />

            {!editStripe ? (
              <div className="mt-2">
                <button
                  className="px-4 w-full h-8 text-sm rounded-[8px] whitespace-nowrap text-white"
                  onClick={() => {
                    setIsClicked(!isClicked);
                    GetStripeCheckout();
                  }}
                  style={{
                    background:
                      "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
                  }}
                >
                  Copy to Clipboard
                </button>
                <button
                  className="px-4 w-full h-8 mt-2 text-sm rounded-[8px] whitespace-nowrap text-white bg-grey"
                  style={{
                    background:
                      "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
                  }}
                  onClick={() => {
                    setEditStripe(!editStripe);
                  }}
                >
                  Edit Stripe Membership
                </button>
              </div>
            ) : (
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder={"Current Price: " + totalprice + " €"}
                  onChange={(e) => {
                    setTotalPrice(e.target.value);
                  }}
                  className="outline-none text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm pr-8"
                ></input>
                <div className="flex space-x-3">
                  <button
                    className="px-4 w-full h-8 mt-2 text-sm rounded-[8px] whitespace-nowrap text-white bg-grey"
                    style={{
                      background:
                        "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
                    }}
                    onClick={() => {
                      setEditStripe(!editStripe);
                    }}
                  >
                    Confirm Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isLookup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 w-full">
          <div className="bg-gray-900  inset-0"></div>
          <div className="flex-col m-20 border order-1 border-stroke w-2/3 px-4 py-6 rounded-md mt-[-50px] bg-dark-grey">
            <div className="flex">
              <div className="bg-inputs p-3 border border-stroke rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 -2 15 22"
                  fill="#6A6E81"
                >
                  <path
                    opacity="0.4"
                    d="M12.6079 11.9524H9.01694C8.66658 11.9524 8.38162 12.2419 8.38162 12.5978C8.38162 12.9543 8.66658 13.2431 9.01694 13.2431H12.6079C12.9583 13.2431 13.2432 12.9543 13.2432 12.5978C13.2432 12.2419 12.9583 11.9524 12.6079 11.9524"
                    fill="#7C829F"
                  />
                  <path
                    d="M6.50119 4.35383L9.90398 7.10343C9.98606 7.16919 10.0001 7.28986 9.93601 7.37392L5.90191 12.6304C5.64832 12.9552 5.2746 13.1389 4.8742 13.1457L2.67196 13.1728C2.5545 13.1741 2.45173 13.0928 2.42504 12.9762L1.92453 10.8001C1.83777 10.4001 1.92453 9.98659 2.17812 9.66797L6.23225 4.38637C6.29764 4.30163 6.41843 4.28671 6.50119 4.35383"
                    fill="#7C829F"
                  />
                  <path
                    opacity="0.4"
                    d="M11.4274 5.46465L10.7714 6.28356C10.7053 6.36694 10.5865 6.3805 10.5045 6.31407C9.70698 5.6687 7.6649 4.01256 7.09832 3.55361C7.01557 3.48582 7.00423 3.36516 7.07096 3.28109L7.70361 2.4954C8.27752 1.75647 9.27854 1.68868 10.086 2.3327L11.0136 3.07162C11.394 3.3699 11.6476 3.76309 11.7344 4.17661C11.8345 4.63149 11.7277 5.07824 11.4274 5.46465"
                    fill="#7C829F"
                  />
                </svg>
              </div>
              <div className="flex-grow-1 mx-3 w-full">
                <div className="flex">
                  <h3 className="text-lg text-white font-bold">
                    Manage All Client's Subscriptions.
                  </h3>
                  <button
                    onClick={() => {
                      setLookup(false);
                      // PreviewUser();
                    }}
                    className="ml-auto"
                  >
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3.95984"
                          y="5.9397"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-45 3.95984 5.9397)"
                          fill="#8A8A8A"
                        />
                        <rect
                          x="5.93964"
                          y="15.8391"
                          width="2.8"
                          height="14"
                          rx="1"
                          transform="rotate(-135 5.93964 15.8391)"
                          fill="#8A8A8A"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className="flex">
                  <p className="text-gray-500">Select a Option</p>
                </div>
              </div>
            </div>

            <hr className="my-4 border-t-1 border-stroke" />
            <div className="flex space-x-4">
                <input
                  type="text"
                  placeholder={"Price of the new subscription"}
                  onChange={(e) => {
                    let NewPrice = e.target.value;
                    setNewPrice(NewPrice);
                    setTotalPrice(totalprice + NewPrice);
                    ;
                  }}
                  className="outline-none text-gray-400 mb-4 border border-t h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm pr-8"
                ></input>
              <Select
                className=" text-gray-400 mb-4  h-10 border-stroke rounded bg-inputs indent-2 w-full text-sm outline-none"
                options={options}
                isMulti
                isClearable={true}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#252631",
                    backgroundColor: "#191B27",
                    outline: "none",
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    height: 40,
                    overflow: "auto",
                  }),
                }}
                onChange={(selectedOptions) => {
                  setSelectedValues(selectedOptions);
                }}
                value={selectedValues}
              />
            </div>
            <button
              className="mt-[-20px] ml-auto px-6 h-8 w-auto text-sm rounded-[8px] whitespace-nowrap text-white"
              style={{
                background:
                  "linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%), linear-gradient(90deg, #6166DC 0%, #9397E8 100%), linear-gradient(135deg, #6FBFFC 0%, #1468F6 100%)",
              }}
              onClick={() => {
                handleSubmit()
                console.log(NewPrice)
                GetNewStripeCheckout();
                setLookup(!isLookup);
              }}
            >
              Add a new sub
            </button>
          </div>
        </div>
      )}

      <div className="flex justify-between mt-2 border order p-2 border-stroke rounded-md">
        <img className="w-10 rounded-md" src={userImage} />
        <p className="text-gray-400 mx-8 mt-2 flex">
          <p>{username} </p>
        </p>

        <p className="text-blue-300 mt-2 mx-10">{member.user.usertype}</p>
        <p className="text-gray-400 mx-10 mt-2">
          {member.user.stripeentry.expiryTime.split("T")[0]}
        </p>

        <button
          className={`mx-5 ${isUserActive ? "text-green-300" : "text-red-300"}`}
        >
          {isUserActive ? "Active" : "Inactive"}
        </button>

        <p className="text-yellow-100 mx-10 mt-2">
        ≈ {totalprice} €
          </p>

        <div className="flex">
          <button
            onClick={() => {
              setIsClicked(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              className="fill-gray-500"
            >
              <path d="M13.479 9.883c-1.626-.604-2.512-1.067-2.512-1.803 0-.622.511-.977 1.423-.977 1.667 0 3.379.642 4.558 1.22l.666-4.111c-.935-.446-2.847-1.177-5.49-1.177-1.87 0-3.425.489-4.536 1.401-1.155.954-1.757 2.334-1.757 4 0 3.023 1.847 4.312 4.847 5.403 1.936.688 2.579 1.178 2.579 1.934 0 .732-.629 1.155-1.762 1.155-1.403 0-3.716-.689-5.231-1.578l-.674 4.157c1.304.732 3.705 1.488 6.197 1.488 1.976 0 3.624-.467 4.735-1.356 1.245-.977 1.89-2.422 1.89-4.289 0-3.091-1.889-4.38-4.935-5.468h.002z"></path>
            </svg>
          </button>

          <button
            onClick={() => {
              PreviewUser();
              // setLookup(true);
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="mx-8 fill-gray-500"
            >
              <path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path>
              <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z"></path>
            </svg>
          </button>

          <button
            className="mt-3"
            onClick={() => {
             
              setEditMember(!EditMember);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="#6A6E81"
            >
              <path
                opacity="0.4"
                d="M12.6079 11.9524H9.01694C8.66658 11.9524 8.38162 12.2419 8.38162 12.5978C8.38162 12.9543 8.66658 13.2431 9.01694 13.2431H12.6079C12.9583 13.2431 13.2432 12.9543 13.2432 12.5978C13.2432 12.2419 12.9583 11.9524 12.6079 11.9524"
                fill="#7C829F"
              />
              <path
                d="M6.50119 4.35383L9.90398 7.10343C9.98606 7.16919 10.0001 7.28986 9.93601 7.37392L5.90191 12.6304C5.64832 12.9552 5.2746 13.1389 4.8742 13.1457L2.67196 13.1728C2.5545 13.1741 2.45173 13.0928 2.42504 12.9762L1.92453 10.8001C1.83777 10.4001 1.92453 9.98659 2.17812 9.66797L6.23225 4.38637C6.29764 4.30163 6.41843 4.28671 6.50119 4.35383"
                fill="#7C829F"
              />
              <path
                opacity="0.4"
                d="M11.4274 5.46465L10.7714 6.28356C10.7053 6.36694 10.5865 6.3805 10.5045 6.31407C9.70698 5.6687 7.6649 4.01256 7.09832 3.55361C7.01557 3.48582 7.00423 3.36516 7.07096 3.28109L7.70361 2.4954C8.27752 1.75647 9.27854 1.68868 10.086 2.3327L11.0136 3.07162C11.394 3.3699 11.6476 3.76309 11.7344 4.17661C11.8345 4.63149 11.7277 5.07824 11.4274 5.46465"
                fill="#7C829F"
              />
            </svg>
          </button>

          <button
            onClick={() => {
              setDeleteIsClicked(!DeleteMenuClicked);
            }}
          >
            <svg
              className="mx-4"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2152 3.93226C15.5069 3.93226 15.7498 4.17451 15.7498 4.48276V4.76776C15.7498 5.0685 15.5069 5.31825 15.2152 5.31825H2.78522C2.49273 5.31825 2.24983 5.0685 2.24983 4.76776V4.48276C2.24983 4.17451 2.49273 3.93226 2.78522 3.93226H4.97201C5.41622 3.93226 5.80281 3.61652 5.90274 3.17102L6.01726 2.65953C6.19523 1.96278 6.78095 1.50004 7.45129 1.50004H10.5484C11.2114 1.50004 11.8037 1.96278 11.9751 2.62278L12.0977 3.17027C12.1969 3.61652 12.5834 3.93226 13.0284 3.93226H15.2152ZM14.1041 14.3506C14.3324 12.2229 14.7321 7.16791 14.7321 7.11691C14.7467 6.96241 14.6964 6.81617 14.5964 6.69842C14.4892 6.58817 14.3535 6.52292 14.204 6.52292H3.8011C3.65084 6.52292 3.50787 6.58817 3.40867 6.69842C3.30801 6.81617 3.25841 6.96241 3.26571 7.11691C3.26705 7.12628 3.28139 7.30435 3.30537 7.60204C3.41189 8.92448 3.70858 12.6077 3.9003 14.3506C4.03597 15.6346 4.87844 16.4416 6.09875 16.4708C7.04043 16.4926 8.01055 16.5001 9.00255 16.5001C9.93693 16.5001 10.8859 16.4926 11.8567 16.4708C13.1194 16.4491 13.9611 15.6563 14.1041 14.3506Z"
                fill="#6A6E81"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default SingleMember;
