import { useState } from "react";
import jwtDecode from "jwt-decode";

const WelcomeBack = ({ user }) => {
  const [userid, setUserID] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [username, setUserName] = useState("");

  return (
    <div className="flex flex-col m-20 border order border-stroke px-4 py-4 rounded-md bg-dark-grey">
      <div className="flex items-center justify-between w-full py-1">
        {/* <img
          className="w-16 h-16 mr-4 rounded-md"
          src={`https://cdn.discordapp.com/avatars/${userid}/${userAvatar}.png`}
        /> */}
        <div className="mx-1">
          <h3 className="text-lg text-white font-bold">
            Welcome Back {username}
          </h3>
          <div className="flex">
            <div className="flex-grow">
              <p className="text-gray-500">We're glad to have you back!.</p>
            </div>
          </div>
        </div>
        <div className="ml-auto">
          <button className="mt-[-20px] ml-auto px-4 h-8 w-auto bg-white text-black rounded-[8px] whitespace-nowrap">
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack;
