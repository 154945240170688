import { useState, useEffect } from "react";
import TrashSvg from "../assets/trash.svg";
import MonitorRunningGif from "../assets/monitor_running.gif";

const RunningMonitors = (jwt) => {
  const [websiteitems, setWebsiteItems] = useState([
 

  ]);

  let headersList = {
    Accept: "*/*",
    authorization: jwt.jwt,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://status.l3xington.dev/api/v1/status/apps", {
          headers: headersList,
        });
        const data = await response.json();
        console.log(data)


        const initialWebsiteItems = Object.keys(data.apps).map((appName, index) => ({
          id: index + 1, // You may need to adjust the id creation logic
          name: appName,
          selected: false,
        }));


        setWebsiteItems(initialWebsiteItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  console.log(websiteitems)


  const handleItemClick = (itemId) => {
    const updatedItems = websiteitems.map((item) => {
      console.log(item.id,itemId)
      if (item.id === itemId) {
        return { ...item,products:1, selected: true };
      } else {
        return { ...item,products:1, selected: false };
      }
    });
    setWebsiteItems(updatedItems);
  };


  return (
    <div className="flex flex-col mr-20 mt-28 border order border-stroke min-w-400 w-3/4 px-4 py-6 rounded-md bg-dark-grey">
      <div className="flex items-center w-full py-4 mt-[-12px]">
        <div className="bg-inputs p-4 border border-stroke rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#8A8A8A"
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
            />
          </svg>
        </div>
        <div className="mx-4">
          <h3 className="text-lg text-white font-bold">Running Monitors</h3>
          <div className="flex">
            <div className="flex-grow">
              <p className="text-gray-500">
                Below you see all running monitors.
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="border-t border-stroke mb-4" />

      <div className="grid grid-cols-2 gap-4 ">
         {websiteitems.map((item) => (
                      <MonitorItem
                        key={item.id}
                        onClick={() => handleItemClick(item.id)}
                        name={item.name}
                        products={item.products}
                        selected={item.selected}
                      />
                    ))}
      </div>

    
    </div>
  );
};

const MonitorItem = ({onClick,name,products,selected}) =>{
  return <>
      <button
      className={`w-54 h-[54px] bg-inputs rounded-[7px] p-1 border ${
        selected ? "border-indigo-400" : "border-stroke"
      }`}
      onClick={onClick}
    >
      <div className="mx-2">
        <div className="flex items-center justify-between">
          <div className="text-gray-400 text-base font-semibold">{name}</div>

          <img className="w-[17px] h-[17px] rounded-[37px] ml-3" src={TrashSvg} />
        </div>

        <div className="flex justify-between">
          <div className="text-gray-400 text-opacity-60 text-xs font-medium ">
            {products} Pids monitored
          </div>

        
          <img
            className="w-[19px] h-[19px] rounded-[37px] "
              src={MonitorRunningGif}
          />

        </div>
      </div>
    </button>
  </>
}
 
export default RunningMonitors;
