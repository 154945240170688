import { useState, useEffect } from "react";
import "boxicons/css/boxicons.min.css";
import WelcomeBack from "./components/WelcomeBack";
import NavBar from "./components/NavigationBar";
import HandleClients from "./components/HandleClients";
import jwtDecode from "jwt-decode";
import LoginPage from "./components/LoginPage";
import AddPidElem from "./components/Add_Pid";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RunningMonitors from "./components/RunningMonitors"

export default function App() {
  const [authed, setAuth] = useState(false);
  const [webhook, setWebhook] = useState([]);
  const [jwt, setJWT] = useState(localStorage.getItem("jwt"));
  const urlParams = new URLSearchParams(window.location.search);
  const [user, setUser] = useState();

  const check = () => {
    if (localStorage.getItem("jwt") !== null) {
      let headersList = {
        Accept: "*/*",
        authorization: jwt,
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        jwt: localStorage.getItem("jwt"),
      });
      if (localStorage.getItem("jwt") !== null) {
        fetch("https://backend.l3xington.dev/api/v2/auth/admin", {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              console.log("Response JSON success is true");
              setAuth(true);
              setJWT(data.token);
              localStorage.setItem("jwt", data.token);
            } else {
              console.log("Response JSON success is false or not present");
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <>
      {!authed ? (
        <LoginPage setAuth={setAuth} user={user} setJWT={setJWT} />
      ) : (
        <div>
         <BrowserRouter>
            <NavBar />
            <Routes>
              <Route
                  path="/"
                  element={
                    <>
                      <div className="flex mt-5">
                        <div className="w-full">
                          <WelcomeBack />
                          <HandleClients jwt={jwt} />
                        </div>
                      </div>
                    </>
                  }
                ></Route>

              <Route
                  path="/monitors"
                  element={
                    <>
                      <div className="w-full flex mt-2">
                        <AddPidElem jwt={jwt}/>
                        <RunningMonitors jwt={jwt}/>
                      </div>
                    
                    </>
                  }
                ></Route>
              </Routes>
           
          </BrowserRouter>
        </div>
      )}
    </>
  );
}
